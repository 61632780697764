import React, { useState } from 'react'

import PlanCard from './PlanCard.tsx';

import standard from '../images/standard.png'
import business from '../images/business.png'
import enterprise from '../images/enterprise.png'

const Plans = () => {
    const [checked, setChecked] = useState(false);
    return (
        <>
            <div className='plans-main' id='pricing'>
                <div className='plans-context'>
                    <div className='plans-context-text'>
                        <h2>
                            Flexible Plans for Every Business Journey!
                        </h2>
                        <p>
                            Find your perfect fit with scalable plans. Maximize value at every stage of your growth.
                        </p>
                    </div>
                    <div className='plans-context-button'>
                        <p>
                            Monthly
                        </p>
                        <div>
                            <div className="form-check form-switch">
                                <input className="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckDefault"
                                    onChange={(e) => setChecked(e.target.checked)}
                                    checked={checked} />
                            </div>
                        </div>
                        <p>
                            Yearly
                        </p>
                    </div>
                </div>

                {checked ? (
                    <div className='plansCard-main'>
                        <PlanCard
                            index={1}
                            imgSrc={standard}
                            packageName='Standard'
                            para='Unleash the Power of Your Business with Pro Plan.'
                            price='$1,524'
                            per='per year'
                            saving='Save 13%'
                            bulletHeading={[
                                'Recordings',
                                'Local Minutes',
                                'Toll Free Minutes',
                                'Local Numbers',
                                'Toll Free Numbers'
                            ]}
                            rates={[
                                '$0.01/Minute',
                                '$0.055/Minute',
                                '$0.06/Minute',
                                '$3/Month',
                                '$4/Minute',
                            ]}
                        />
                        <PlanCard
                            index={2}
                            imgSrc={business}
                            packageName='Business'
                            para='Take Your Business to the Next Level with Business Plan.'
                            price='$2,364'
                            per='per year'
                            saving='Save 33%'
                            bulletHeading={[
                                'Recordings',
                                'Local Minutes',
                                'Toll Free Minutes',
                                'Local Numbers',
                                'Toll Free Numbers'
                            ]}
                            rates={[
                                '$0.005/Minute',
                                '$0.05/Minute',
                                '$0.055/Minute',
                                '$2/Month',
                                '$3/Minute',
                            ]}
                        />
                        <PlanCard
                            index={3}
                            imgSrc={enterprise}
                            packageName='Enterprise'
                            para='Unleash the Power of Your Business with Enterprise Plan.'
                            price='Custom'
                            per='per year'
                            // saving='Save $650'
                            bulletHeading={[
                                'Recordings',
                                'Local Minutes',
                                'Toll Free Minutes',
                                'Local Numbers',
                                'Toll Free Numbers'
                            ]}
                            rates={[
                                'Custom',
                                'Custom',
                                'Custom',
                                'Custom',
                                'Custom',
                            ]}
                        />
                    </div>
                ) : (
                    <div className='plansCard-main'>
                        <PlanCard
                            index={1}
                            imgSrc={standard}
                            packageName='Standard'
                            para='Unleash the Power of Your Business with Pro Plan.'
                            price='$147'
                            per='per month'
                            bulletHeading={[
                                'Recordings',
                                'Local Minutes',
                                'Toll Free Minutes',
                                'Local Numbers',
                                'Toll Free Numbers'
                            ]}
                            rates={[
                                '$0.01/Minute',
                                '$0.055/Minute',
                                '$0.06/Minute',
                                '$3/Month',
                                '$4/Minute',
                            ]}
                        />
                        <PlanCard
                            index={2}
                            imgSrc={business}
                            packageName='Business'
                            para='Take Your Business to the Next Level with Business Plan.'
                            price='$290'
                            per='per month'
                            bulletHeading={[
                                'Recordings',
                                'Local Minutes',
                                'Toll Free Minutes',
                                'Local Numbers',
                                'Toll Free Numbers'
                            ]}
                            rates={[
                                '$0.005/Minute',
                                '$0.05/Minute',
                                '$0.055/Minute',
                                '$2/Month',
                                '$3/Minute',
                            ]}
                        />
                        <PlanCard
                            index={3}
                            imgSrc={enterprise}
                            packageName='Enterprise'
                            para='Unleash the Power of Your Business with Enterprise Plan.'
                            price='Custom'
                            per='per month'
                            bulletHeading={[
                                'Recordings',
                                'Local Minutes',
                                'Toll Free Minutes',
                                'Local Numbers',
                                'Toll Free Numbers'
                            ]}
                            rates={[
                                'Custom',
                                'Custom',
                                'Custom',
                                'Custom',
                                'Custom',
                            ]}
                        />
                    </div>
                )}
            </div>
        </>
    )
}

export default Plans
